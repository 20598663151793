import React from 'react'
import Video from '../components/video'

const htmlSerializer = (type, node, text, children, key) => {
  var linkid = text?.toLowerCase().replace(/[^A-Za-z0-9]/g, '')
  switch (type) {
    case 'embed':
      return <Video embed={node.oembed.embed_url} />
    case 'heading1':
      return (
        <h1 key={key} id={linkid}>
          {children}
        </h1>
      )
    case 'heading2':
      return (
        <h2 key={key} id={linkid}>
          {children}
        </h2>
      )
    case 'heading3':
      return (
        <h3 key={key} id={linkid}>
          {children}
        </h3>
      )
    case 'heading4':
      return (
        <h4 key={key} id={linkid}>
          {children}
        </h4>
      )
    case 'heading5':
      return (
        <h5 key={key} id={linkid}>
          {children}
        </h5>
      )
    case 'heading6':
      return (
        <h6 key={key} id={linkid}>
          {children}
        </h6>
      )
    case 'image':
      return (
        <div key={key} className='block-img'>
          <img src={node.url} alt={node.alt || ''} />
          {node.alt && <small>{node.alt}</small>}
        </div>
      )
    default:
      return null
  }
}

export default htmlSerializer
