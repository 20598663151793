import React from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Video = ({ slice, embed }) => {
  if (embed === null && slice.primary.video === null) return null
  if (slice && slice.primary && slice.primary.video !== null) {
    embed = slice.primary.video.embed_url
  }
  if (embed?.includes('watch')) {
    embed = embed.split('v=')[1]
    var ampersandPosition = embed.indexOf('&')
    if (ampersandPosition !== -1) {
      embed = embed.substring(0, ampersandPosition)
    }
  }
  if (embed?.includes('shorts')) {
    embed = embed.split('shorts/')[1]
  }
  if (embed?.includes('youtu.be')) {
    embed = embed.split('youtu.be/')[1]
  }
  return (
    <section className='component-video mx-auto w-full max-w-screen-lg py-10 px-4'>
      <div className='relative aspect-video'>
        <LiteYouTubeEmbed iframeClass='h-full' id={embed} />
      </div>
    </section>
  )
}

export default Video
